* {
  margin: 0;
  padding: 0;
}
body {
  background: #272B46;
  color: #C7D8E8;
  font-family: Inter,-apple-system,system-ui,Roboto,"Helvetica Neue",Arial,sans-serif;
  padding-bottom: 20px;
}
